import type StatamicCollectionRequest from "~/types/requests/StatamicCollectionRequest";
import type SelectedFeature from "~/types/SelectedFeature";
import type TestimonialType from "~/types/Testimonial";
import axios from "axios";
import type Product from "~/types/Product";

/**
 * Add general helpers here
 */
export function useHelper() {
    // Data
    const config = useRuntimeConfig();
    const collectionProductFields =
        "c_id,c_slug,coursewave_product_id,isbn" +
        ",title,price_base,price_bulk,color_option,price_color_base,price_color_bulk,digital_option,price_digital," +
        "featured,features,subjects,qty_25_minimum,photo_sm,photo_sm_2x,photo,photo_2x,photo_lg,photo_lg_2x" +
        ",photo_xl,photo_xl_2x,photo_2xl,photo_2xl_2x" +
        ",photo_3xl,photo_3xl_2x";

    // Functions
    /**
     *
     * @param e
     */
    function getApiError(e: any): string {
        // Get validation errors
        if (e.response?.status == 422 && e.response?.data.errors) {
            const aryErrors: any[] = [];
            for (const value of Object.values(e.response.data.errors)) {
                if (Array.isArray(value)) {
                    aryErrors.push(value.join("<br/>"));
                }
            }

            return '<div class="text-abc-red">' + aryErrors.join("<br/>") + "</div>";
        } else if (e.response?.status == 400 && e.response?.data.message) {
            return '<div class="text-abc-red">' + e.response.data.message + "</div>";
        } else if (e.response?.status == 401 && e.response?.data.message) {
            return '<div class="text-abc-red">' + e.response.data.message + "</div>";
        }

        return '<div class="text-abc-red">Failed to process</div>';
    }

    /**
     *
     * @param month
     * @param year
     */
    function formatCardExpire(month: number, year: number): string {
        return String(month).padStart(2, "0") + "/" + String(year).substr(-2);
    }

    /**
     * Upper case first letter of every words
     * @param words
     */
    function upperCaseFirstLetter(words: string): string {
        if (!words) {
            return "";
        }

        const ary = words.split(" ");
        for (let i = 0; i < ary.length; i++) {
            ary[i] = ary[i][0].toUpperCase() + ary[i].substring(1);
        }
        return ary.join(" ");
    }

    function emailIsValid(value: string) {
        const EMAIL_REGEX =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return EMAIL_REGEX.test(value);
    }

    async function assetsFileExists(url: string) {
        const { data } = await axios.get(config.public.STATAMIC_API + `/assets-file-exists?file=${url}`);

        return data.exist ?? false;
    }

    /**
     * Get filtered link
     * @param obj
     */
    function selectedFeatureLink(obj: SelectedFeature): string {
        let link = `/products/state/${obj.state.slug}?`;
        const filters = [];

        if (obj.sort) {
            filters.push("sortBy=" + obj.sort.key);
        }

        // Grade filters
        const aryGrades = [];
        for (const grade of obj.grades) {
            aryGrades.push(grade.slug);
        }
        if (aryGrades) {
            filters.push("grades=" + aryGrades.join(","));
        }

        // Subject filters
        const arySubjects = [];
        for (const subject of obj.subjects) {
            arySubjects.push(subject.slug);
        }
        if (arySubjects) {
            filters.push("subjects=" + arySubjects.join(","));
        }

        // Series filters
        const arySeries = [];
        for (const serie of obj.series) {
            arySeries.push(serie.slug);
        }
        if (arySeries) {
            filters.push("series=" + arySeries.join(","));
        }

        link += filters.join("&");
        return link;
    }

    /**
     * Return options as key/value
     *
     * @param taxonomy
     */
    async function getTaxonomyOptions(taxonomy: string) {
        try {
            // Grades aren't going to change so we can hard code them
            if (taxonomy.toLowerCase() === "grades") {
                return [
                    { key: "k", value: "Kindergarten" },
                    { key: "1", value: "Grade 1" },
                    { key: "2", value: "Grade 2" },
                    { key: "3", value: "Grade 3" },
                    { key: "4", value: "Grade 4" },
                    { key: "5", value: "Grade 5" },
                    { key: "6", value: "Grade 6" },
                    { key: "7", value: "Grade 7" },
                    { key: "8", value: "Grade 8" },
                    { key: "9", value: "Grade 9" },
                    { key: "10", value: "Grade 10" },
                    { key: "11", value: "Grade 11" },
                    { key: "12", value: "Grade 12" },
                ];
            }

            // Cached taxonomy options
            const maxAge = 60 * 60 * 24 * 7; // 7 day;
            const taxonomyData =
                typeof useCookie !== "undefined" ? useCookie(taxonomy.toLowerCase(), { maxAge }) : ref(null);
            if (taxonomyData.value) {
                return taxonomyData.value;
            }

            const url = config.public.STATAMIC_API + `/taxonomies/${taxonomy}/terms`;
            const { data } = await useFetch<StatamicCollectionRequest>(url);
            const arrayObjs = data.value?.data as { slug: string; title: string }[];

            const options: { key: string; value: string }[] = [];
            if (arrayObjs) {
                for (const obj of Object.values(arrayObjs)) {
                    options.push({
                        key: obj.slug,
                        value: obj.title,
                    });
                }

                // Save to cache
                if (typeof useCookie !== "undefined") {
                    taxonomyData.value = JSON.stringify(options);
                }
            }

            return options;
        } catch (e) {
            console.log(e);
        }
    }

    async function getCollection(collection: string, filtersAndFields = "", limit = 10, page = 1, sort = "") {
        try {
            const params = [];
            if (filtersAndFields) {
                params.push(filtersAndFields);
            }
            if (sort) {
                params.push("sort=" + sort);
            }

            const url =
                config.public.STATAMIC_API +
                `/collections/${collection}/entries?` +
                params.join("&") +
                `&limit=${limit}&page=${page}`;
            const { data, error } = await useFetch<StatamicCollectionRequest>(url);

            if (error.value) {
                throw createError({
                    ...error.value,
                    statusMessage: `Could not fetch data from ${url}`,
                });
            }

            return data.value?.data ?? [];
        } catch (e) {
            console.log(e);
        }
    }

    async function getTestimonials(
        userState: string,
        onlyVideo = false,
        limit = 6,
        curTestimonials: TestimonialType[] = [],
    ) {
        const filters = [];
        if (userState) {
            filters.push(`filter[taxonomy:states]=${userState.toLowerCase()}`);
        }
        if (onlyVideo) {
            filters.push("filter[video_url:is_url]=true");
        }
        if (curTestimonials.length > 0) {
            const ids = curTestimonials.map((a) => a.id);
            if (ids.length === 1) {
                filters.push(`filter[id:not]=${ids[0]}`);
            } else if (ids.length > 1) {
                filters.push(`filter[id:not_in]=${ids.join("|")}`);
            }
        }

        return (await getCollection("testimonials", filters.join("&"), limit, 1, "order")) as TestimonialType[];
    }

    async function getProduct(product_id: number) {
        const filtersAndFields = `filter[id:is]=${product_id}`;
        const products = await getCollection("products", filtersAndFields, 1);
        return products?.at(0) as Product | undefined;
    }

    function encrypt(value: string) {
        try {
            return window.btoa(value);
        } catch (e) {
            console.log(e);
            return "";
        }
    }

    function decrypt(value: string) {
        try {
            return window.atob(value);
        } catch (e) {
            console.log(e);
            return "";
        }
    }

    function formatDate(date: string): string {
        // Need to replace the date string dash with slash. Otherwise, it will be one day behind.
        return new Date(date.replace(/-/g, "/").replace(/T.+/, "")).toLocaleDateString();
    }

    return {
        getApiError,
        formatCardExpire,
        upperCaseFirstLetter,
        emailIsValid,
        assetsFileExists,
        selectedFeatureLink,
        getTaxonomyOptions,
        getCollection,
        collectionProductFields,
        getProduct,
        getTestimonials,
        encrypt,
        decrypt,
        formatDate,
    };
}
